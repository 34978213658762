@import './variables';


.menubar {
    background: $light-background;
}

.footerContainer {
    background: $light-background;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}