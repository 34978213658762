@import './variables';
@import './utils';
@import './utils';

@import './landing.scss';
@import './dashboard.scss';
@import './patient_table.scss';
@import './bulk_uploads.scss';

@import "./register";


.card-header {
    text-transform: uppercase;
    color: $light-text;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 15px;
}

.main-header {
    text-transform: uppercase;
    color: $light-text;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 20px;
}

.header-title {
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.notifiable {
    color: red;
}