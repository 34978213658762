// Place all the styles related to the BulkUploads controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/


.bulkuploadsTable {
    td {
        word-break: break-all;
    }
    .bulkUploadsRow {
        height: 60px;
    }
}
