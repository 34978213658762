@import './variables';

.patientNotifications {
    border-top: 10px solid $light-background;
    .notificationsTable {
        height: auto;
    }
    .tableHeader {
        tr {
            th {
                color: $light-text;
            }
        }
    }
    .dashboardTableRow {
        height: 60px;
    }
}